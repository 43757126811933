/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-06",
    versionChannel: "nightly",
    buildDate: "2024-09-06T00:06:03.493Z",
    commitHash: "5550443fdb27d6318e3882949dedffd48725e05a",
};
